import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { LoginService } from '../pages/login/login.service';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(
    private authService: LoginService,
    private router: Router
  ) { }

  public canActivate() {
    if (this.authService.check()) {
      this.router.navigateByUrl('/home');
      return false;
    } else {
      return true;
    }
  }
}
