import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'solicitations',
    loadChildren: () => import('./pages/solicitations/solicitations.module').then( m => m.SolicitationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'works',
    loadChildren: () => import('./pages/works/works.module').then( m => m.WorksModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
