import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService } from '../pages/login/login.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: LoginService,
    private router: Router,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot) {
    if (this.authService.check()) {
      return true;
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }

  public canActivateChild() {
    if (this.authService.check()) {
      return true;
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}