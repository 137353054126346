import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform, NavController } from '@ionic/angular';
import { LoginService } from './pages/login/login.service';
import { LoadingService } from './shared/loading.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public selectedIndex = 0;
  public appPages = []

  constructor(
    private platform: Platform,
    public loginService: LoginService,
    private navCtrl: NavController,
    private loadingService: LoadingService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      await SplashScreen.show({
        showDuration: 2000,
        autoHide: true,
      });
    });
  }

  ngOnInit() {
    const path = window.location.pathname;
    this.selectedIndex = 0;
    this.appPages = [
      {
        title: 'início',
        url: '/home',
        icon: 'home',
      },
      {
        title: 'Meu Ponto',
        url: '/works',
        icon: 'time',
      },
      {
        title: 'Minhas Solicitações',
        url: '/solicitations',
        icon: 'person-circle',
      },
      {
        title: 'Solicitações',
        url: '/solicitations/employees',
        icon: 'people',
      }
      // 1 col
      // 2 admin
    ];
    this.selectedIndex = this.appPages.findIndex(page => page.url.toLowerCase() === path.toLowerCase());
  }

  public async logout() {
    this.loadingService.showLoading();
    this.loginService.logout().subscribe(
      response => {
        this.loadingService.hideLoading();
        localStorage.clear();
        window.location.reload();
        this.navCtrl.navigateRoot('/login');
      }, (error: HttpErrorResponse) => {
        this.loadingService.hideLoading();
      }
    )
  }

  public setMenu(url) {
    if (url == "/solicitations/employees") {
      return this.loginService.getEmployee() && this.loginService.getEmployee().group == 2 ? true : false
    }
    return true;
  }
}
