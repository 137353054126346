import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler):
  Observable<HttpEvent<any>> {
    const url: Array<any> = req.url.split('/');
    const apiUrl: Array<any> = environment.host.split('/')
    const token = localStorage.getItem('token');
    if (token && (url[2] === apiUrl[2])) {
      const newRequest = req.clone({ setHeaders: {'Authorization': `Bearer ${token}`} });
      return next.handle(newRequest);
    } else {
      return next.handle(req);
    }
  }
}