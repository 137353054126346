import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  
  private url = `${environment.host}/authenticate`;

  constructor(
    private http: HttpClient
  ) { }

  public check(): boolean {
    return localStorage.getItem('user') ? true : false;
  }

  public getUser() {
    return localStorage.getItem('user') ? JSON.parse(localStorage.getItem( 'user')) : null;
  }

  public getEmployee() {
    return localStorage.getItem('employee') ? JSON.parse(localStorage.getItem( 'employee')) : null;
  }

  public logout(): Observable<any> {
    return this.http.get(`${this.url}/logout`);
  }

  public signIn(params): Observable<any> {
    return this.http.post(`${this.url}/sign_in`, params);
  }

}
